import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'

import ArticleCard from '../components/article-card'
import CustomReactShare from '../components/custom-react-share'
import SEO from '../components/seo'
import SectionHeading from '../components/section-header'
import { SimilarItemsGenerator } from '../models/item-similarity-generator'

const Article = styled.article`
  ${tw``};
  blockquote {
    ${tw`bg-grey-lightest dark:bg-grey-darkest`};

    p {
      ${tw`p-4 border-t-0 border-r-0 border-b-0 border-l-4 border-blue-lighter border-solid`}
      strong:first-child {
        display: block;
      }
    }
  }
`
const Header = styled.section`
  ${tw`w-full md:w-2/3 mx-auto text-center my-12 md:mb-16`};
`
const Tags = styled.div`
  ${tw`text-sm font-serif font-semibold tracking-wide uppercase`};
`
const Concept = styled.span`
  ${tw`text-indigo`};
`
const Title = styled.h1`
  ${tw`text-4xl md:text-5xl text-black dark:text-white leading-tight font-normal mt-5 mb-3 md:my-4`};
`
const PublishDate = styled.div`
  ${tw`mt-0 md:mt-0`};
`
const Image = styled(GatsbyImage)`
  ${tw`text-red mt-6 mb-8 md:mb-12 rounded-sm`};
`
const Content = styled.section`
  ${tw`w-full lg:w-3/4 xl:w-2/3 lg:mx-auto font-sans`};

  h2,
  h3,
  h4 {
    ${tw`md:text-5xl text-black dark:text-white leading-tight font-normal`};
  }

  h2 {
    ${tw`text-4xl`};
  }
  h3 {
    ${tw`text-3xl`};
  }
  h4 {
    ${tw`text-2xl`};
  }
`

const Section = styled.section`
  ${tw`mb-4 mt-8`};
`

const RecommendedReading = styled.div`
  ${tw`flex flex-wrap my-4`};
`

class BlogPostTemplate extends React.Component {
  render() {
    deckDeckGoHighlightElement()
    const post = get(this, 'props.data.contentfulBlogPost')
    const { tags, slug } = post
    const siteMetadata = get(this, 'props.data.site.siteMetadata')
    const recommendedReading = get(
      this,
      'props.data.recommendedReading.edges'
    ).map(({ node }) => node)
    const timeToRead = post.body.childMarkdownRemark.timeToRead
    const similarArticles = new SimilarItemsGenerator(recommendedReading, slug)
      .setTags(tags)
      .getItems()

    return (
      <>
        <SEO
          title={post.title}
          description={post.description}
          image={post.heroImage.gatsbyImageData}
          pathname={this.props.location.pathname}
          keywords={post.tags}
          meta={[
            {
              name: 'article:published_time',
              content: post.publishDate || new Date().toString(),
            },
            {
              name: 'article:modified_time',
              content: post.updatedAt || new Date().toString(),
            },
            {
              name: 'twitter:label1',
              content: 'Author',
            },
            {
              name: 'twitter:data1',
              content: siteMetadata.social.twitter,
            },
            {
              name: 'twitter:label2',
              content: 'Reading Time',
            },
            {
              name: 'article:data2',
              content: `${timeToRead} ${
                timeToRead === 1 ? 'Minute' : 'Minutes'
              }`,
            },
          ]}
        />
        <Article id="main">
          <Header>
            <Tags>
              {post.tags.map((tag, i) => (
                <span className="mb-0" key={tag}>
                  {tag}
                  {i !== post.tags.length - 1 && <span>&nbsp;·&nbsp;</span>}
                </span>
              ))}
              {post.concept === true && (
                <>
                  <span>&nbsp;·&nbsp;</span>
                  <Concept>Concept</Concept>
                </>
              )}
            </Tags>
            <Title>{post.title}</Title>
            <PublishDate>{post.publishDate}</PublishDate>
            <CustomReactShare
              marginTop="8px"
              showTitle={false}
              title={post.title}
              url={`${siteMetadata.siteUrl}${this.props.location.pathname}`}
            />
          </Header>
          <Image
            alt={post.title}
            title={post.title}
            image={post.heroImage.gatsbyImageData}
          />
          <Content
            className="content"
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
          <CustomReactShare
            title={post.title}
            url={`${siteMetadata.siteUrl}${this.props.location.pathname}`}
          />
          <Section id="recommendedReading">
            <SectionHeading heading="Related Articles" />
            <RecommendedReading>
              {similarArticles.map(entry => {
                return <ArticleCard article={entry} key={entry.slug} />
              })}
            </RecommendedReading>
          </Section>
        </Article>
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      concept
      publishDate(formatString: "MMMM Do, YYYY")
      updatedAt(formatString: "MMMM Do, YYYY")
      tags
      heroImage {
        gatsbyImageData(
          quality: 90
          width: 1600
          layout: CONSTRAINED
          placeholder: TRACED_SVG
        )
      }
      metaImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      description
      body {
        childMarkdownRemark {
          html
          timeToRead
          excerpt(pruneLength: 155)
        }
      }
    }
    recommendedReading: allContentfulBlogPost(
      filter: { slug: { ne: $slug } }
      sort: { fields: [publishDate], order: [DESC] }
      limit: 24
    ) {
      edges {
        node {
          title
          titleAbridged
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            gatsbyImageData(
              quality: 90
              width: 800
              height: 450
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
            )
          }
          body {
            childMarkdownRemark {
              excerpt(pruneLength: 75)
            }
          }
          description
        }
      }
    }
  }
`
